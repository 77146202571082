.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.lds-side-nav {
  z-index: 1 !important;
  padding-top: 5rem;
  /* width: 15rem !important; */
}
.lds-side-nav-scroll-container {
  overflow-y: hidden !important;
}
.lds-side-nav-content {
  overflow-x: hidden !important;
  /* margin-left: 15rem !important; */
}
.lds-select-input-wrapper .lds-select {
color: black;

}

#header > div > div.lds-header-menu-container.header-mobile.right > nav > div.lds-header-menu-scroll-container > ul > li:nth-child(1) > div > div > div {
  width: 10rem;
}
.lds-select {
  text-overflow: ellipsis;
}
#requestTable.lds-table {
  p {
    margin-bottom: 0;
  }
}